<script setup lang="ts"></script>

<template>
  <v-app>
    <main-navigation />

    <v-main style="--v-layout-top: calc(var(--logo-height-l) + var(--spacer))">
      <v-container class="pb-0 h-100">
        <slot />
      </v-container>
    </v-main>

    <hw-footer />
  </v-app>
</template>
